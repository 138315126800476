import { authStorage, keycloak, KEYCLOAK_HOST, KEYCLOAK_REALM } from '@/config';

export const kcInstance = async () => {
  if (!keycloak.canRefresh()) return null;
  const exp = keycloak.getExpireIn();
  if (33 > exp) {
    try {
      await keycloak.refreshToken(33);
    } catch (e) {
      logout();
      return null;
    }
  }
  return keycloak.instance;
};

export const logout = async (redirectUri?: string) => {
  sessionStorage.clear();
  await authStorage.store(undefined, false);
  const uri = redirectUri
    ? window.location.origin + redirectUri
    : window.location.origin;
  const idToken = keycloak.instance.idToken;

  if (idToken) {
    window.open(
      `${KEYCLOAK_HOST}/realms/${KEYCLOAK_REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${uri}&id_token_hint=${idToken}`,
      '_self',
    );
  } else {
    window.open(window.location.origin, '_self');
  }
};
