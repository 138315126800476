/**
 * Copyright 2018 Samsung Electronics All Rights Reserved
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { Omit } from 'utility-types';
import { createFieldInput, CustomCommonField } from './CreateFieldInputCustom';

const { TextArea } = Input;

export type inputComponents =
  | 'id'
  | 'value'
  | 'defaultValue'
  | 'onChange'
  | 'onKeyDown'
  | 'onReset';
export interface FieldTextAreaProps
  extends Omit<TextAreaProps, inputComponents>,
    CustomCommonField {}

export const DebouncedFieldTextArea = createFieldInput<
  FieldTextAreaProps,
  InstanceType<typeof Input>
>(TextArea, {
  inputClass: 'sw-field-textarea',
  displayName: 'FieldTextArea',
});
