import _ from 'lodash';
export function formatDate(input: any) {
  const date = new Date(input);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return `${
    months[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()} ${('0' + date.getHours()).slice(
    -2,
  )}:${('0' + date.getMinutes()).slice(-2)}`;
}

export const isAllowedUpload = (file: any, type: string[]) => {
  if (type.indexOf(file.type) !== -1) return true;
  return false;
};

export const isAllowedUploadImage = (file: any) => {
  const nullRegex = /[%][0zZO]{2}/gm;
  const isPNG = file.type === 'image/png';
  const isJPEG = file.type === 'image/jpeg';
  const isJPG = file.type === 'image/jpg';
  const isSVGXML = file.type === 'image/svg+xml';
  const isSVG = file.type === 'image/svg';
  const isGIF = file.type === 'image/gif';
  const isProhibited = file.name.match(nullRegex) === null;
  if (
    (!isPNG && !isJPEG && !isJPG && !isSVG && !isSVGXML && !isGIF) ||
    !isProhibited
  ) {
    return false;
  }
  return true;
};
export const isAllowedUploadJSON = (file: any) => {
  const nullRegex = /[%][0zZO]{2}/gm;
  const isAnimation = file.type === 'application/json';
  const isProhibited = file.name.match(nullRegex) === null;
  if (!isAnimation || !isProhibited) {
    return false;
  }
  return true;
};

export const isAllowedUploadCSV = (file: any) => {
  const nullRegex = /[%][0zZO]{2}/gm;
  const isCSV1 = file.type === 'application/vnd.ms-excel';
  const isCSV2 = file.type === 'text/csv';
  const isProhibited = file.name.match(nullRegex) === null;
  if ((!isCSV1 && !isCSV2) || !isProhibited) {
    return false;
  }
  return true;
};

export const isAllowedUploadExcel = (file: any) => {
  const nullRegex = /[%][0zZO]{2}/gm;
  const isExcel =
    file.type ===
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const isProhibited = file.name.match(nullRegex) === null;
  if (!isExcel || !isProhibited) {
    return false;
  }
  return true;
};

export const isAllowedUploadZip = (file: any) => {
  const nullRegex = /[%][0zZO]{2}/gm;
  const isZip = file.type === 'application/x-zip-compressed';
  const isProhibited = file.name.match(nullRegex) === null;
  if (!isZip || !isProhibited) {
    return false;
  }
  return true;
};

export const insertSortedArray = (
  array: any[],
  value: any,
  orderBy: string | undefined = undefined,
) => {
  array.splice(_.sortedIndexBy(array, value, orderBy), 0, value);
  return array;
};

export const orderArray = (
  array: any[],
  orderBy: string[] | undefined = undefined,
  dir: (boolean | 'asc' | 'desc')[] = ['asc'],
) => {
  return _.orderBy(array, orderBy, dir);
};

export const objectIsEqual = (x: any, y: any) => {
  return _.isEqual(x, y);
};
