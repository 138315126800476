import { createStandardAction, ActionType } from 'typesafe-actions';

export const globalActions = {
  setConfig: createStandardAction('DB_SET_CONFIG_GLOBAL')<object>(),
  setDashboardPermission: createStandardAction('DB_SET_PERMISSION')<object>(),
  setTheme: createStandardAction('DB_SET_THEME')<object>(),
  setExport: createStandardAction('DB_SET_EXPORT')<object>(),
};

export type GlobalActions = ActionType<typeof globalActions>;
