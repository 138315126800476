import React, { forwardRef, Component, ComponentType, useState } from 'react';
import classnames from 'classnames';
import { CommonFieldProps, useFormField, formActions, FormField } from 'swuif';
import debounce from 'lodash.debounce';

export interface CreateFieldInputOption {
  inputClass: string;
  displayName: string;
}
export interface CustomCommonField extends CommonFieldProps {
  onKeyDown?: (event: any, data: any) => any;
}
export function createFieldInput<
  P extends CustomCommonField,
  C extends Component<any>
>(InputComponent: ComponentType<any>, opts: CreateFieldInputOption) {
  const FieldComponent = forwardRef<C, P>((props, ref) => {
    const { className, id, onChange, onPressEnter, onKeyDown } = props as any;
    const [form, dispatch, formFieldProps, fieldProps] = useFormField(props);
    const [localState, setLocalState] = useState<any>(form.data[id] || '');

    const debouncedSetData = React.useCallback(debounce<any>(dispatch, 150), [
      dispatch,
    ]);
    const useOnBlur = (
      props: CommonFieldProps & { onBlur?: any },
      localState: any,
    ): ((ev: any) => any) => {
      const { validateOn, id, onBlur } = props;

      const _onBlur = React.useCallback(
        (ev: any) => {
          if (onBlur) {
            onBlur(ev);
          }
          if (localState !== form.data[id]) {
            dispatch(formActions.setData(localState, id));
          }
          if (validateOn === 'change' || validateOn === 'blur') {
            dispatch(formActions.validate(id));
          }
        },
        [id, onBlur, validateOn, localState],
      );

      return _onBlur;
    };

    const _onChange = (ev: any) => {
      // InputNumber passed value directly instead of SyntheticEvent.
      let value = ev.target && ev.target.value !== null ? ev.target.value : ev;
      if (onChange) {
        const val = onChange(value, form.data);
        if (val !== null) {
          value = val;
        }
      }
      setLocalState(value);
      debouncedSetData(formActions.setData(value, id));
    };

    const onBlur = useOnBlur(props, localState);

    const _onPressEnter = (e: any) => {
      /* istanbul ignore else */
      if (onPressEnter == null) {
        dispatch(formActions.submit());
      } else if (onPressEnter !== null) {
        onPressEnter(e);
      }
    };

    const _onKeyDown = (e: any) => {
      e.persist();
      if (onKeyDown) {
        const data = onKeyDown(e, localState);
        if (data !== localState) {
          setLocalState(data);
          dispatch(formActions.setData(data, id));
        }
      }
    };

    return (
      <FormField {...formFieldProps}>
        <InputComponent
          ref={ref}
          id={id}
          data-testid={id}
          value={localState}
          onChange={_onChange}
          {...fieldProps}
          onBlur={onBlur}
          onPressEnter={_onPressEnter}
          onKeyDown={_onKeyDown}
          className={classnames(className, 'sw-form-field', opts.inputClass)}
        />
      </FormField>
    );
  });
  FieldComponent.displayName = opts.displayName;
  return FieldComponent;
}
