/**
 * Copyright 2018 Samsung Electronics All Rights Reserved
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Input } from 'antd';
import { InputProps } from 'antd/es/input';
import { Omit } from 'utility-types';
import { createFieldInput, CustomCommonField } from './CreateFieldInputCustom';

export type fieldInputType =
  | 'id'
  | 'value'
  | 'defaultValue'
  | 'onChange'
  | 'onKeyDown'
  | 'onReset';

/**
 * FieldInput component props.
 */
export interface FieldInputProps
  extends Omit<InputProps, fieldInputType>,
    CustomCommonField {}

/**
 * An input component as FormField and can be used in `Form`.
 *
 * @remarks
 * Wraps antd's `Input` component in `Form` context so it can be used within
 * any `Form` component and have its value managed.
 *
 * Uses `React.forwardRef` under the hood so you can safely provide `ref` prop
 * and have antd`s `Input` original component back to call its methods like
 * `focus()`, etc.
 *
 * As with any other FormField component, you have to provide `id` property
 * to identify which `data` key this field represents.
 */
export const DebouncedFieldInput = createFieldInput<FieldInputProps, Input>(
  Input,
  {
    inputClass: 'sw-field-input',
    displayName: 'FieldInput',
  },
);
