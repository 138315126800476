import Keycloak from 'keycloak-js';
import { KEYCLOAK_HOST, KEYCLOAK_REALM, KEYCLOAK_CLIENTID } from './constants';

const kc: Keycloak.KeycloakInstance = Keycloak({
  url: KEYCLOAK_HOST,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENTID,
});

export const keycloak = {
  instance: kc,

  canRefresh: () => {
    return kc && kc.tokenParsed && kc.refreshToken;
  },
  getExpireIn: () => {
    var expireIn = 987654321; // unable to determine Expire time
    if (kc && kc.tokenParsed && kc.refreshToken) {
      const timeNow = Math.ceil(new Date().getTime() / 1000);
      expireIn = (kc.timeSkew || 0) + (kc.tokenParsed['exp'] || 0) - timeNow;
    }
    // console.log('kc expire in: ' + expireIn); // TODO: should be disabled (not for production)
    return expireIn;
  },
  refreshToken: (minValidity = -1) => {
    return kc.updateToken(minValidity);
  },
};
