import React from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadingPage = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
      }}
    >
      <LoadingSpinner />
    </div>
  );
};

export default LoadingPage;
