import { getType } from 'typesafe-actions';
import { globalActions, GlobalActions } from './actions';
import { DEFAULT_CONFIG_OBJECT } from '@/components/dashboard/constants';

export interface commonGlobalState {
  globalConfig: object;
  dashboardPermission: object;
  theme: object;
  export_datas: any;
}

export const initialState: commonGlobalState = {
  globalConfig: DEFAULT_CONFIG_OBJECT,
  dashboardPermission: {},
  theme: {},
  export_datas: {},
};

export const reducer = (
  state: commonGlobalState = initialState,
  action: GlobalActions,
): commonGlobalState => {
  switch (action.type) {
    case getType(globalActions.setConfig):
      // filter object
      return {
        ...state,
        globalConfig: action.payload,
      };
    case getType(globalActions.setDashboardPermission):
      return {
        ...state,
        dashboardPermission: action.payload,
      };
    case getType(globalActions.setTheme):
      return {
        ...state,
        theme: action.payload,
      };
    case getType(globalActions.setExport):
      return {
        ...state,
        export_datas: action.payload,
      };

    default:
      return state;
  }
};
