import React, { createContext, Dispatch, useReducer, ReactNode } from 'react';
import { GlobalActions } from './actions';
import { reducer, initialState, commonGlobalState } from './reducer';
import { DEFAULT_CONFIG_OBJECT } from '@/components/dashboard/constants';
import themeData from './theme.json';

export type GlobalContextType = [commonGlobalState, Dispatch<GlobalActions>];
export const GlobalContext = createContext<GlobalContextType | undefined>(
  undefined,
);

export interface GlobalContextProviderProps {
  children?: ReactNode;
  config?: object;
  dashboardPermission?: object;
  theme?: object;
  export_datas?: any;
}

export const GlobalContextProvider = ({
  children,
  config = DEFAULT_CONFIG_OBJECT,
  dashboardPermission = {},
  theme = themeData['default'],
  export_datas = {},
}: GlobalContextProviderProps) => {
  const value = useReducer(reducer, initialState, init => ({
    ...init,
    config: config,
    dashboardPermission: dashboardPermission,
    theme: theme,
    export_datas: export_datas,
  }));

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
