import React, { Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Page403, SwuifApp } from 'swuif';
import Main from '@/routes/Main';
import '@/style/index.less';
import { swuifPreset } from '@/config';
import Login from '@/routes/Main/Login';
import RegistrationForm from '@/routes/Main/Registration/RegistrationForm';
import ActivityMonitoring from './ActivityMonitoring';
import LoadingPage from './LoadingPage';

const frontPage = {
  main: <Route strict path="/" component={Main} />,
  login: <Route exact path="/" component={Login} />,
};

const App = (props: { status: string }) => {
  return (
    <SwuifApp {...swuifPreset}>
      <BrowserRouter>
        <Suspense fallback={<LoadingPage />}>
          <ActivityMonitoring>
            <Switch>
              <Route strict path="/403" component={Page403} />
              <Route exact path="/register" component={RegistrationForm} />
              {frontPage[props.status]}
              <Redirect to="/" />
            </Switch>
          </ActivityMonitoring>
        </Suspense>
      </BrowserRouter>
    </SwuifApp>
  );
};

export default hot(App);
