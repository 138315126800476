import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { notification } from 'antd';
import React from 'react';

interface OpenNotificationProps {
  message: string;
  description: React.ReactNode;
  type: string;
}

export const openNotification = ({
  message,
  description,
  type,
}: OpenNotificationProps) => {
  return notification[type]({
    message: message,
    description: description,
    duration: 5,
    className: `message-${type}`,
    icon:
      type === 'success' ? <CheckCircleFilled /> : <ExclamationCircleFilled />,
  });
};
