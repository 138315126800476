export const DEFAULT_METADATA: object = {};
export const DEFAULT_DATA: Array<object> = [];
export const DEFAULT_QUERY: Array<object> = [];
export const DEFAULT_RENDERER: 'canvas' | 'svg' = 'svg';
export const DEFAULT_OPTION_WORDCLOUD: object = {};
export const DEFAULT_FUNCTION = () => {};

export const DEFAULT_FILTER_LIST: Array<object> = [];
export const DEFAULT_MULTISELECT_FILTER_VALUE: Array<object> = [];

export const DEFAULT_FILTER_OBJECT: object = {};
export const DEFAULT_CONFIG_OBJECT: object = {};
