import { sbaasPreset } from '@swuif/sbaas';
// import appLogo from '@/assets/SmartThings-Analytics-Dashboardv.1.0.2-1.svg';
import antdEn from 'antd/es/locale-provider/en_US';
// import antdId from 'antd/es/locale-provider/id_ID';
// import antdKr from 'antd/es/locale-provider/ko_KR';
import i18n from './i18n';
import { authStorage } from './swuif';
import appLogo from '@/assets/logo.svg';
import { AuthStorage } from 'swuif';
import {
  IErrorInterceptor,
  IRequest,
  IRequestInterceptor,
  IResponse,
} from 'frest';
import { keycloak } from '.';
import { logout } from '@/routes/AuthLogin';

const REACT_APP_SBAAS_BASE = process.env.REACT_APP_SBAAS_BASE
  ? process.env.REACT_APP_SBAAS_BASE
  : '';
const REACT_APP_SBAAS_APP = process.env.REACT_APP_SBAAS_APP
  ? process.env.REACT_APP_SBAAS_APP
  : 'st-cms';
const REACT_APP_SBAAS_ASSETS_APP = process.env.REACT_APP_SBAAS_ASSETS_APP
  ? process.env.REACT_APP_SBAAS_ASSETS_APP
  : 'st-assets';
/**
 * Create a preset for SBaaS consuming application.
 * `sbaasPreset` is for SBaaS version >= 1.0.0.
 * For SBaaS version < 1.0.0 use `sbaasLegacyPreset` instead.
 */
const { http: http_stcms_api, sbaas: sbaas_stcms_api } = sbaasPreset({
  sbaas: {
    base: REACT_APP_SBAAS_BASE,
    app: REACT_APP_SBAAS_APP,
  },
  auth: {
    storage: authStorage,
  },
  config: {
    app: {
      appName: 'SmartThings Analytics',
      appLogo,
    },
    locale: {
      i18n,
      fallbackLanguage: 'en',
      languageMeta: {
        en: {
          antd: antdEn,
          countryFlag: 'gb',
          label: 'English',
        },
        // id: {
        //   antd: antdId,
        //   countryFlag: 'id',
        //   label: 'Bahasa Indonesia',
        // },
        // kr: {
        //   antd: antdKr,
        //   countryFlag: 'kr',
        //   label: 'Korean',
        // },
      },
    },
  },
});

const { http: http_assets_api, sbaas: sbaas_assets_api } = sbaasPreset({
  sbaas: {
    base: REACT_APP_SBAAS_BASE,
    app: REACT_APP_SBAAS_ASSETS_APP,
  },
  auth: {
    storage: authStorage,
  },
  config: {
    app: {
      appName: '',
      appLogo,
    },
    locale: {
      i18n,
      fallbackLanguage: 'en',
      languageMeta: {
        en: {
          antd: antdEn,
          countryFlag: 'gb',
          label: 'English',
        },
      },
    },
  },
});

const { app, http, sbaas } = sbaasPreset({
  sbaas: {
    base: REACT_APP_SBAAS_BASE,
    app: REACT_APP_SBAAS_APP,
  },
  auth: {
    storage: authStorage,
  },
  config: {
    app: {
      appName: 'SmartThings CMS',
      appLogo,
    },
    locale: {
      i18n,
      fallbackLanguage: 'en',
      languageMeta: {
        en: {
          antd: antdEn,
          countryFlag: 'gb',
          label: 'English',
        },
        // id: {
        //   antd: antdId,
        //   countryFlag: 'id',
        //   label: 'Bahasa Indonesia',
        // },
        // kr: {
        //   antd: antdKr,
        //   countryFlag: 'kr',
        //   label: 'Korean',
        // },
      },
    },
  },
});

const expireMargin = 33;
const refreshToken = (request: IRequest) => {
  return keycloak.refreshToken(expireMargin).then(refreshed => {
    if (refreshed) {
      //   console.warn('Token was successfully refreshed');
      // } else {
      //   console.warn('Token is still valid');
    }
    request.headers.set(
      'Authorization',
      `Bearer ${keycloak.instance.token}`, // newly refreshed token
    );
    return request;
  });
};

const customSbaasAuthRequestInterceptor = (): IRequestInterceptor => ({
  request,
}) =>
  new Promise((resolve, reject) => {
    try {
      if (!keycloak.canRefresh()) {
        // need relogin
        logout();
        return;
      }
      const exp = keycloak.getExpireIn();
      if (expireMargin > exp) {
        refreshToken(request)
          .then(resolve)
          .catch(e => {
            // console.warn(['--- catch updateToken: ',err])
            if (!keycloak.canRefresh()) {
              logout();
              return;
            }
            reject(e); // keycloak server is offline/busy?
          });
        return;
      } else {
        request.headers.set(
          'Authorization',
          `Bearer ${keycloak.instance.token}`,
        );
      }
      resolve(request);
    } catch (e) {
      reject(e);
    }
  });

http_stcms_api.interceptors.request.use(customSbaasAuthRequestInterceptor());
http_assets_api.interceptors.request.use(customSbaasAuthRequestInterceptor());

const customSbaasAuthErrorInterceptor = (
  _storage: AuthStorage,
  _app: string,
): IErrorInterceptor => err =>
  new Promise<IResponse<any> | null | undefined>((resolve, _reject) => {
    const { frest, request, response } = err;
    if (response && response.data && response.status === 401) {
      if (request.retry == null) {
        request.retry = 0;
      }
      // Note: update max retry here
      if (!keycloak.canRefresh()) {
        logout();
        return;
      } else if (request.retry >= 3) {
        // invalid role?
        // console.log('invalid role?');
      } else {
        request.retry += 1;
        refreshToken(request)
          .then(req => frest.request(req))
          .then(resolve)
          .catch(_e => {
            // console.warn(['--- catch updateToken: ',err])
            if (!keycloak.canRefresh()) {
              logout();
              return;
            }
            resolve(null); // keycloak server is offline/busy?
          });
        return;
      }
    }
    resolve(null);
  });

// Note: remove existing interceptors
let intLenStcms = http_stcms_api.interceptors.error.handlers.length;
let intLenAssets = http_assets_api.interceptors.error.handlers.length;
while (intLenStcms > 0) {
  http_stcms_api.interceptors.error.eject(intLenStcms - 1);
  intLenStcms--;
}
while (intLenAssets > 0) {
  http_assets_api.interceptors.error.eject(intLenAssets - 1);
  intLenAssets--;
}

// Note: use our custom interceptor
http_stcms_api.interceptors.error.use(
  customSbaasAuthErrorInterceptor(app.auth!.storage!, REACT_APP_SBAAS_APP),
);
http_assets_api.interceptors.error.use(
  customSbaasAuthErrorInterceptor(
    app.auth!.storage!,
    REACT_APP_SBAAS_ASSETS_APP,
  ),
);

export { app, http, sbaas, sbaas_stcms_api, sbaas_assets_api };
