import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import swuifEn from '@swuif/locales/en/swuif.json';
import swuifId from '@swuif/locales/id/swuif.json';
import en from '../locales/en/translation.json';
import id from '../locales/id/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {
        swuif: swuifEn,
        translation: en,
      },
      id: {
        swuif: swuifId,
        translation: id,
      },
    },
  });

export default i18n;
