import React from 'react';
import { Spin } from 'antd';

export interface LoadingSpinnerProps {
  nowLoading?: boolean;
}

const LoadingSpinner = ({ nowLoading = true }: LoadingSpinnerProps) => {
  return (
    <Spin className="custom-center" tip="Loading..." spinning={nowLoading} />
  );
};

export default LoadingSpinner;
